import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
export var initialState = {
  AUTH_LOGIN: false,
  CHAPTER_COMPLETE: false,
  COURSE_FETCH: false,
  DELEGATED_COURSES_FETCH: false,
  REPORT_ACTIVITIES_FETCH: false,
  REPORT_GROUPS_FETCH: false,
  REPORT_USERS_FETCH: false,
  REPORT_COLLECTIONS_FETCH: false,
  REPORT_COURSES_FETCH: false,
  USER_FETCH: false
};
var reducerCoreLoading = function reducerCoreLoading() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  var type = action.type,
    payload = action.payload;
  var matches = /(.*)_(ERROR|START|SUCCESS)/.exec(type);
  if (!matches) {
    return state;
  }
  var _matches = _slicedToArray(matches, 3),
    requestName = _matches[1],
    requestState = _matches[2];
  var isStart = requestState === 'START';
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, requestName, isStart && payload ? payload : isStart));
};
export default reducerCoreLoading;