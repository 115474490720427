var fontFamily = function fontFamily() {
  for (var _len = arguments.length, fonts = new Array(_len), _key = 0; _key < _len; _key++) {
    fonts[_key] = arguments[_key];
  }
  return "system-ui, -apple-system, \"Segoe UI\", ".concat(fonts.join(','), ", \"Helvetica Neue\", Arial, \"Noto Sans\", sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\", \"Noto Color Emoji\"");
};
var darkBlueColor = "#2883ff";
export var theme = {
  mode: 'LIGHT',
  breakpoint: {
    small: '320px',
    medium: '800px',
    semilarge: '1000px',
    large: '1200px'
  },
  color: {
    white: '#ffffff',
    black: '#000000',
    athensGray: '#f5f7f9',
    zircon: '#f8fbff',
    lightGray: '#EFF2F6',
    catskillWhite: '#eff3f7',
    // slate-200
    alto: '#e0e0e0',
    mystic: '#e3e6eb',
    geyser: '#d2d8e0',
    cadetBlue: '#AEB8C5',
    gullGray: '#96a0ba',
    raven: '#777d8b',
    nevada: '#686f79',
    grayChateau: '#a1a5af',
    silverChalice: '#a5a5a5',
    jumbo: '#828286',
    stormGray: '#787b85',
    shuttleGray: '#515c68',
    midGray: '#5f5f64',
    mako: '#43474d',
    abbey: '#505757',
    brightGray: '#39404a',
    mirage: '#191d26',
    portGore: '#202743',
    lightBlack: '#212123',
    darkBlue: darkBlueColor,
    success: '#00b977',
    secondary: '#f0eff7',
    light: '#787b85',
    disabled: '#8da2af',
    danger: '#dc2c30',
    pink: '#ff6384',
    radicalRed: '#ff446e',
    supernova: '#fccf01',
    lightBlue: '#0bb6ff'
  },
  zIndex: {
    hidden: -1,
    colorPicker: 1020,
    dropdown: 1070,
    topLevel: 1071,
    fullScreenWrapper: 1073,
    fullScreenMode: 1076,
    popperMenu: 1076,
    menuList: 1070,
    courseSearchOverlay: 1070,
    courseSearchSuggestionsContainer: 1071,
    courseSearchInputOpen: 1072,
    courseSearchIcon: 1073,
    lightbox: 1075,
    courseCreateWizardOverlay: 1073,
    courseDescriptionOverlay: 1073,
    courseEditorSettingsModalOverlay: 1073,
    popup: 1074,
    listbox: 1075,
    photoEditor: 1075,
    fullScreenBackdrop: 1073,
    fullScreenInner: 1074,
    tooltip: 1074,
    courseCompleted: 1075,
    select: {
      menu: 1074
    },
    dialog: {
      overlay: 1020
    },
    page: {
      sidebar: 1020
    }
  },
  "default": {
    color: '#191a1c',
    transition: '.3s ease-in-out',
    zIndex: 1020,
    box: {
      shadow: {
        "default": '0 10px 20px 0 rgba(46, 51, 56, .1)'
      }
    },
    border: {
      radius: '1rem'
    },
    padding: {
      desktop: '30px',
      "default": '20px'
    },
    font: {
      family: {
        heading: fontFamily('Ubuntu'),
        "default": fontFamily('"Open Sans"')
      },
      size: {
        xxsmall: '0.625rem',
        xsmall: '0.6875rem',
        small: '0.75rem',
        "default": '0.875rem',
        semiLarge: '0.9375rem',
        large: '1rem',
        xlarge: '1.125rem',
        xxlarge: '1.3125rem',
        xxxlarge: '1.5rem'
      }
    }
  },
  input: {
    border: {
      color: '#ced2d6',
      "default": "1px solid #ced2d6"
    }
  },
  layout: {
    background: {
      color: '#f6f7f9'
    }
  },
  page: {
    sidebar: {
      width: '245px',
      profile: {
        width: '330px'
      }
    }
  },
  chapter: {
    sidebar: {
      width: '300px'
    }
  }
};