import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { omit } from 'ramda';
import { combineReducers } from 'redux';
import { REPORT_SELECTED_VALUES_UPDATE_SUCCESS, RESET_ACTIVITIES_FILTER, RESET_COMPLETIONS_FILTER } from '@/redux/actionTypes/training-reports/main';
import reducerCoursesReport from '@/redux/reducers/reports-courses';
import reducerGroupsReport from '@/redux/reducers/reports-groups';
import reducerUsersReport from '@/redux/reducers/reports-users';
export var defaultCollectionItem = {
  name: '',
  value: ''
};
export var defaultChartType = {
  name: 'Progress',
  value: 'progress'
};

/* eslint-disable @typescript-eslint/no-empty-interface */

/* eslint-disable @typescript-eslint/no-empty-interface */

export var initialActivitiesFilterState = {
  selectedActivitiesInterval: defaultCollectionItem,
  selectedActivitiesFrom: null,
  selectedActivitiesTo: null,
  selectedActivitiesLevels: [],
  selectedActivitiesGroups: [],
  selectedActivitiesDescendants: false,
  areActivitiesFilterDefaultsSet: false,
  defaultAppType: defaultCollectionItem,
  defaultInterval: defaultCollectionItem,
  appType: defaultCollectionItem
};
export var initialCompletionsFilterState = {
  selectedPublishStatuses: [],
  selectedGroupsChildren: false,
  selectedPublishedFromFrom: null,
  selectedPublishedFromTo: null,
  selectedPublishedUntilFrom: null,
  selectedPublishedUntilTo: null,
  selectedLastCompletionFrom: null,
  selectedLastCompletionTo: null,
  selectedAggregateByGroupUsers: false,
  selectedLevel: defaultCollectionItem,
  selectedGroups: [],
  selectedCourses: [],
  selectedUsers: [],
  selectedPositions: [],
  selectedKinds: [],
  defaultGroups: [],
  defaultChartType: defaultChartType,
  defaultAggregateByGroupUsers: true,
  defaultDescendants: false,
  defaultKinds: [],
  chartType: defaultCollectionItem,
  defaultStatuses: [],
  areCompletionsFilterDefaultsSet: false
};
export var resetCompletionsFilterState = omit(['chartType', 'defaultChartType'], initialCompletionsFilterState);
export var initialState = {
  main: _objectSpread(_objectSpread({
    levels: [],
    intervals: [],
    activitiesData: [],
    appTypes: []
  }, initialActivitiesFilterState), initialCompletionsFilterState),
  groups: {
    data: {
      series: [],
      metadata: []
    },
    filters: initialCompletionsFilterState
  },
  users: {
    data: {
      series: [],
      metadata: []
    },
    filters: initialCompletionsFilterState
  },
  courses: {
    data: {
      series: [],
      metadata: []
    },
    filters: initialCompletionsFilterState
  }
};
export var reducerMainReport = function reducerMainReport() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState.main;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case REPORT_SELECTED_VALUES_UPDATE_SUCCESS:
      return _objectSpread(_objectSpread({}, state), action.payload);
    case RESET_ACTIVITIES_FILTER:
      return _objectSpread(_objectSpread({}, state), {}, {
        activitiesData: initialState.main.activitiesData
      }, initialActivitiesFilterState);
    case RESET_COMPLETIONS_FILTER:
      return _objectSpread(_objectSpread({}, state), resetCompletionsFilterState);
    default:
      return state;
  }
};
var reducerReport = combineReducers({
  main: reducerMainReport,
  groups: reducerGroupsReport,
  users: reducerUsersReport,
  courses: reducerCoursesReport
});
export default reducerReport;