import * as actions from '@/redux/actionTypes/error';
export var initialState = null;
var errorReducer = function errorReducer() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case actions.ERROR_CATCH:
      return action.payload;
    default:
      return state;
  }
};
export default errorReducer;