import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { REPORT_GROUPS_FETCH_ERROR, REPORT_GROUPS_FETCH_START, REPORT_GROUPS_FETCH_SUCCESS, REPORT_GROUPS_FILTERS_RESET, REPORT_GROUPS_FILTERS_UPDATE } from '@/redux/actionTypes/training-reports/groups';
import { initialCompletionsFilterState } from '@/redux/reducers/initial-completions-state';
export var initialState = {
  data: {
    series: [],
    metadata: []
  },
  filters: initialCompletionsFilterState
};
export var reducerGroupsReport = function reducerGroupsReport() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : initialState;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  switch (action.type) {
    case REPORT_GROUPS_FILTERS_RESET:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: initialState.data,
        filters: _objectSpread(_objectSpread({}, initialCompletionsFilterState), {}, {
          chartType: state.filters.chartType,
          selectedAggregateByGroupUsers: true
        })
      });
    case REPORT_GROUPS_FETCH_ERROR:
    case REPORT_GROUPS_FETCH_START:
      return _objectSpread(_objectSpread({}, state), {}, {
        filters: _objectSpread(_objectSpread({}, initialCompletionsFilterState), state.filters)
      });
    case REPORT_GROUPS_FETCH_SUCCESS:
      return _objectSpread(_objectSpread({}, state), {}, {
        data: action.payload
      });
    case REPORT_GROUPS_FILTERS_UPDATE:
      return _objectSpread(_objectSpread({}, state), {}, {
        filters: action.payload
      });
    default:
      return state;
  }
};
export default reducerGroupsReport;