import _slicedToArray from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/slicedToArray.js";
/* eslint no-console: 0 */
import * as Sentry from '@sentry/nextjs';
var defaultOptions = {
  sentry: true
};
var isSentry = function isSentry() {
  return Boolean(process.env.SENTRY_DSN);
};
var isTest = function isTest() {
  return false;
};
var errorNormalizer = function errorNormalizer(err) {
  var _ref, _err$message;
  if (!err) return new Error('Unexpected');
  if (err instanceof Error) return err;
  var newErr = new Error(typeof err === 'string' ? err : (_ref = (_err$message = err.message) !== null && _err$message !== void 0 ? _err$message : err.error_description) !== null && _ref !== void 0 ? _ref : 'Unexpected');
  for (var _i = 0, _Object$entries = Object.entries(err); _i < _Object$entries.length; _i++) {
    var _Object$entries$_i = _slicedToArray(_Object$entries[_i], 2),
      key = _Object$entries$_i[0],
      value = _Object$entries$_i[1];
    newErr[key] = value;
  }
  return newErr;
};
var error = function error(exception) {
  var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;
  var tags = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
  if (isSentry() && options.sentry) {
    Sentry.captureException(errorNormalizer(exception), {
      level: 'error',
      extra: extra,
      tags: tags
    });
  } else if (!isTest()) {
    console.error(exception, extra);
  }
  return exception;
};
var info = function info(message) {
  var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;
  if (isSentry() && options.sentry) {
    Sentry.captureMessage(message, {
      level: 'info',
      extra: extra
    });
  } else if (!isTest()) {
    console.info(message, extra);
  }
  return message;
};
var report = function report() {
  if (isSentry()) {
    var eventId = Sentry.lastEventId();
    Sentry.showReportDialog({
      eventId: eventId
    });
  }
};
var warning = function warning(message) {
  var extra = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : defaultOptions;
  if (isSentry() && options.sentry) {
    Sentry.captureMessage(message, {
      level: 'warning',
      extra: extra
    });
  } else if (!isTest()) {
    console.warn(message, extra);
  }
  return message;
};
export default {
  error: error,
  info: info,
  report: report,
  warning: warning
};