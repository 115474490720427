import _defineProperty from "/vercel/path0/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import Logger from '@ui/utils/logger';
import Router from 'next/router';
import { p } from '@/lib/path-utils';
import UserAgent from '@/lib/user-agent';
import { ERROR_CATCH } from '@/redux/actionTypes/error';
export function catchError(error) {
  var extras = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var extraOptions = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var defaultOptions = {
    logoutOnUnauthorized: true,
    propagateError: true
  };
  var options = _objectSpread(_objectSpread({}, defaultOptions), extraOptions);
  var sentry = !error.response;
  return function (dispatch) {
    var _error$response;
    var status = error === null || error === void 0 || (_error$response = error.response) === null || _error$response === void 0 ? void 0 : _error$response.status;
    var logOut = options.logoutOnUnauthorized && status === 401 && !UserAgent.isMobileApp();
    if (logOut) {
      Router.push(p('/signout'));
      return;
    }
    if (options.propagateError) {
      Logger.error(error, extras, {
        sentry: sentry
      });
      dispatch({
        type: ERROR_CATCH,
        payload: error
      });
    }
  };
}