import { getEnv, getRelease, isProduction } from '@motimate/utils';
import * as Sentry from '@sentry/nextjs';
import UserAgent from '@/lib/user-agent';
var SENTRY_DSN = process.env.SENTRY_DSN;
var initSentry = function initSentry(history) {
  Sentry.init({
    enabled: Boolean(SENTRY_DSN),
    dsn: SENTRY_DSN,
    environment: getEnv(),
    maxValueLength: 2048,
    normalizeDepth: 10,
    release: getRelease(),
    sampleRate: 1,
    tracesSampleRate: isProduction() ? 0.001 : 0,
    tunnel: '/tunnel',
    integrations: [Sentry.reactRouterV5BrowserTracingIntegration({
      history: history
    }), Sentry.extraErrorDataIntegration({
      depth: 5
    })],
    ignoreErrors: [/Cannot read property 'regions' of undefined/, /document\.getElementsByClassName\("docs-homescreen-gb-container"\)\[0\]\.style/, /ResizeObserver loop limit exceeded/,
    // Email link Microsoft Outlook crawler compatibility error
    // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    /Non-Error promise rejection captured with value\: Object Not Found Matching Id\:/],
    beforeSend: function beforeSend(event) {
      if (UserAgent.isErrorSampling()) {
        return event;
      }
      return null;
    }
  });
  if (!UserAgent.isMobileApp()) return;
  var scope = Sentry.getCurrentScope();
  scope.setTag('eventApi.version', UserAgent.getEventApiVersion());
  scope.setTag('platform.name', UserAgent.getPlatformName());
  scope.setTag('platform.release', UserAgent.getPlatformRelease() || 'undefined');
};
export default initSentry;