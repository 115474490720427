import { intlReducer as intl } from 'react-intl-redux';
import { combineReducers } from 'redux';
import core from './core';
import error from './error';
// Training
import trainingReports from './training-reports';
var reducers = function reducers() {
  return combineReducers({
    core: core,
    error: error,
    intl: intl,
    trainingReports: trainingReports
  });
};
export default reducers;