export var defaultCollectionItem = {
  name: '',
  value: ''
};
export var defaultChartType = {
  name: 'Progress',
  value: 'progress'
};
export var initialCompletionsFilterState = {
  selectedPublishStatuses: [],
  selectedGroupsChildren: false,
  selectedPublishedFromFrom: null,
  selectedPublishedFromTo: null,
  selectedPublishedUntilFrom: null,
  selectedPublishedUntilTo: null,
  selectedLastCompletionFrom: null,
  selectedLastCompletionTo: null,
  selectedAggregateByGroupUsers: true,
  selectedLevel: defaultCollectionItem,
  selectedGroups: [],
  selectedCourses: [],
  selectedUsers: [],
  selectedPositions: [],
  selectedKinds: [],
  defaultGroups: [],
  defaultChartType: defaultChartType,
  defaultAggregateByGroupUsers: true,
  defaultDescendants: false,
  defaultKinds: [],
  chartType: defaultCollectionItem,
  defaultStatuses: [],
  areCompletionsFilterDefaultsSet: false
};