import { createSelector } from 'reselect';
var intlSelector = function intlSelector(state) {
  return state.intl;
};
export var getIntl = createSelector(intlSelector, function (intl) {
  return intl;
});
export var getIntlLocale = createSelector(getIntl, function (_ref) {
  var locale = _ref.locale;
  return locale;
});
export var getIntlMessages = createSelector(getIntl, function (_ref2) {
  var messages = _ref2.messages;
  return messages;
});