import { isProduction } from '@motimate/utils';
import * as Sentry from '@sentry/nextjs';
import { applyMiddleware, compose, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import reducers from '@/redux/reducers';
var isSentry = function isSentry() {
  return Boolean(process.env.SENTRY_DSN);
};
var storeCreator = function storeCreator(initialState) {
  var enhancers = [];
  if (isSentry()) {
    enhancers.push(Sentry.createReduxEnhancer({
      stateTransformer: function stateTransformer() {
        return null;
      },
      configureScopeWithState: function configureScopeWithState(scope, state) {
        var _state$user, _state$organization;
        var userId = (_state$user = state.user) === null || _state$user === void 0 || (_state$user = _state$user.data) === null || _state$user === void 0 ? void 0 : _state$user.id;
        var userOrganization = (_state$organization = state.organization) === null || _state$organization === void 0 || (_state$organization = _state$organization.organization) === null || _state$organization === void 0 ? void 0 : _state$organization.identifier_name;
        if (userId) {
          scope.setTag('user.id', userId);
        }
        if (userOrganization) {
          scope.setTag('user.organization', userOrganization);
        }
      }
    }));
  }
  return createStore(reducers(), initialState, isProduction() ? compose.apply(void 0, [applyMiddleware(thunk)].concat(enhancers)) : composeWithDevTools.apply(void 0, [applyMiddleware(thunk)].concat(enhancers)));
};
export default storeCreator;