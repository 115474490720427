/* eslint-disable global-require */

import { useEffect, useRef, useState } from 'react';
import { Provider } from 'react-intl-redux';
import { initAmplitude } from '@/lib/amplitude-utils';
import initSentry from '@/lib/initSentry';
import * as LocaleUtils from '@/lib/locale';
import { fetchLocaleData } from '@/redux/actions/intl';
import storeCreator from '@/redux/store';
import AppInternalLayout from './AppInternalLayout';
import ReactQueryProvider from './ReactQueryProvider';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
var RootLayout = function RootLayout(_ref) {
  var children = _ref.children;
  var storeRef = useRef(null);
  var _useState = useState({
      isMounted: false
    }),
    isMounted = _useState[0].isMounted,
    setState = _useState[1];
  useEffect(function () {
    var locale = LocaleUtils.getLocale();
    fetchLocaleData(locale).then(function (msgs) {
      var initialState = {
        intl: {
          defaultLocale: locale,
          locale: locale,
          messages: msgs[0]
        }
      };
      var store = storeCreator(initialState);

      // @ts-ignore
      storeRef.current = store;
      initSentry(history);
      initAmplitude();
      setState({
        isMounted: true
      });
    });
  }, []);
  if (!isMounted) {
    return null;
  }
  return _jsx(Provider, {
    store: storeRef.current,
    children: _jsx(ReactQueryProvider, {
      children: _jsx(AppInternalLayout, {
        children: children
      })
    })
  });
};
export default RootLayout;