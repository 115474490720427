import auth from '@motimate/auth';
import { getNavigatorLanguage } from '@/lib/locale';
import UserAgent from '@/lib/user-agent';
import { changeLocale } from '@/redux/actions/intl';
export var fetchOrganizationSuccess = function fetchOrganizationSuccess(organization) {
  return function (dispatch) {
    var _authentication$oauth, _authentication$oauth2;
    var preferredLanguage = organization.preferred_language,
      authentication = organization.authentication;
    var navigatorLanguage = getNavigatorLanguage(window.navigator);
    var language = UserAgent.getUserLanguage();
    var locale = language || navigatorLanguage || preferredLanguage;
    var authOauthClientId = (_authentication$oauth = (_authentication$oauth2 = authentication.oauth_client_ids) === null || _authentication$oauth2 === void 0 ? void 0 : _authentication$oauth2.web) !== null && _authentication$oauth !== void 0 ? _authentication$oauth : null;
    auth.setup(organization.identifier_name, UserAgent.isMobileApp() ? {
      baseUrl: organization ? organization.base_url : '',
      mechanism: 'mobile_app',
      name: organization ? organization.name : '',
      logo: {
        url: organization === null || organization === void 0 ? void 0 : organization.assets.logo
      },
      oauthLoginUrl: authentication.mobile_training_third_party_oauth_login_url
    } : {
      baseUrl: organization.base_url,
      externalSignoutUrl: authentication.external_signout_redirect_url,
      externalVendor: authentication.external_vendor,
      identifierType: authentication.identifier_type,
      logo: {
        url: organization.assets.logo
      },
      mechanism: authentication.mechanism,
      name: organization.name,
      oauthClientId: authOauthClientId,
      oauthHost: authentication.external_oauth_host,
      oauthLoginUrl: authentication.web_training_third_party_oauth_login_url,
      oauthResource: authentication.external_oauth_resource,
      oauthSignoutUrl: authentication.web_training_third_party_oauth_signout_url,
      oauthTokenEndpoint: authentication.external_oauth_token_endpoint,
      oauthTokenResource: authentication.external_oauth_token_resource
    });
    dispatch(changeLocale(locale));
  };
};